import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="Contact or hire, Randall Lough, a super nice dog owner and occasional software engineer."
        path="/contact"
        keywords={[
          "contact",
          "hire",
          "contact randall lough",
          "contact randy lough",
          "hire randy lough",
          "hire randall lough",
        ]}
      />
      <section className="bg-gray-800 py-24">
        <div className="max-w-4xl px-5 mx-auto">
          <header className="text-center mb-10">
            <h2 className="text-white text-4xl">Contact me</h2>
          </header>
          <div className="md:w-3/4 mx-auto">
            <div className="bg-gray-100 px-10 py-8 shadow-md w-full rounded">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
